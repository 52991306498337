import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../ui components/Footer";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Navbar from "../ui components/Navbar";
import { FormContext } from "./ContextProvider";
export default function FormName(){
    const [userName, setUserName] = useState("")
    const {getClientName} = useContext(FormContext)

    const navigate = useNavigate()

    const handleNameChange = (event) => {
        setUserName(event.target.value);
        getClientName(event);
      };
    
      const handleRightButtonClick = () => {
        if (userName.trim() !== '') {
          // Only navigate if the TextField is not empty
          navigate('/form-email');
        }
      };
    return(
        <>
        <Navbar currentPage = "form-name" />
        <Box padding={{xs:'10vh 50px', md:'20vh 15vw'}} height={{xs:'50vh', md:'70vh'}}>
        <Typography fontSize='32px' fontWeight='600' fontFamily='segoe UI' margin='0 0 5vh'>
            Hello 👋 what's your full name ?
        </Typography>
        <TextField type="text" variant="standard" placeholder="Your name" name="client_name" fullWidth onChange={handleNameChange}/>
        <div style={{display:'flex', justifyContent:'center', gap:5, marginTop:'30vh'}}>
        <Button
        style={{
            display:'flex',
            justifyContent:'flex-start',
            borderRadius:'100px',
        }}
        disabled
        >
            <ArrowCircleLeftIcon/>
        </Button>
        <Button
        onClick={handleRightButtonClick}
        style={{
            display:'flex',
            justifyContent:'end',
            borderRadius:'100px',
            cursor: userName.trim() === '' ? 'not-allowed' : 'pointer',
            backgroundColor: userName.trim() === '' ? '#f0f0f0' : '#EC2C6D',
            color: userName.trim() === '' ? '#808080' : '#ffffff',
          }}
        >
            <ArrowCircleRightIcon/>
        </Button>
        </div>
        </Box>
        <Footer/>
        </>
    )
}