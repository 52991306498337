import { Box, Button, Grid, Typography } from "@mui/material";
import React, { forwardRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

const Benefits = forwardRef((props, ref) => {
 
  const navigate = useNavigate()
  const {ref: benefitsRef, inView: benefitsIsVisible} = useInView({triggerOnce: true, threshold: 0.5,});
  return (
    <section ref={ref}>
      <Grid container position="relative">
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{background:'linear-gradient(#FDF5EC 40%, transparent 60%)'}}
        >
            <img
              src="./assets/images/benefits-bg-1.png"
              style={{ position: "absolute", width:"clamp(50px, 5vw, 92px)", top: "0", right: "5%" }}
            />
          <Box width='70vw' display='flex'justifyContent='center' alignItems='center' boxSizing='border-box'>
            
          <div ref={benefitsRef} style={{position:'relative', width:'clamp(300px, 50vw, 900px)'}}>
            <img 
            id={`${benefitsIsVisible ? "benefits-second-bg-anm-1" : ""}`} className="benefits-second-bg-anm" src="./assets/images/benefits-second-bg-1.png" />
            <img id={`${benefitsIsVisible ? "benefits-second-bg-anm-2" : ""}`} className="benefits-second-bg-anm" src="./assets/images/benefits-second-bg-2.png" />
            <img id={`${benefitsIsVisible ? "benefits-second-bg-anm-3" : ""}`} className="benefits-second-bg-anm" src="./assets/images/benefits-second-bg-3.png" />
            <img id={`${benefitsIsVisible ? "benefits-second-bg-anm-4" : ""}`} className="benefits-second-bg-anm" src="./assets/images/benefits-second-bg-4.png" />
            <img id={`${benefitsIsVisible ? "benefits-second-bg-anm-5" : ""}`} className="benefits-second-bg-anm" src="./assets/images/benefits-second-bg-5.png" />
            <img id={`${benefitsIsVisible ? "benefits-second-bg-anm-6" : ""}`} className="benefits-second-bg-anm" src="./assets/images/benefits-second-bg-6.png" />
            <img id={`${benefitsIsVisible ? "benefits-second-bg-anm-7" : ""}`} className="benefits-second-bg-anm" src="./assets/images/benefits-second-bg-2.png" />
            <img id={`${benefitsIsVisible ? "benefits-second-bg-anm-8" : ""}`} className="benefits-second-bg-anm" src="./assets/images/benefits-second-bg-3.png" />
          </div>
          </Box>
          <Typography
          className="border-bottom-centered font"
            textAlign="center"
            fontSize="clamp(1.2rem, 3vw, 3rem)"
            fontWeight="700"
            mt={{xs:'25vh', md:'600px'}}
          >
            Benefits for paying the ProxyAgent
          </Typography>
          <Button
          onClick={()=> navigate("/benefits-detail")}
            variant="contained"
            className="font"
            sx={{
              padding: {xs:'7px 20px', md:"7px 70px"},
              mt: "5vh",
              borderRadius: "10px",
              bgcolor: "#EC2C6D",
              textTransform: "none",
              color: "white",
              fontSize: "clamp(1rem, 3vw, 2.5rem)",
              fontWeight: "600",
              "&:hover": { backgroundColor: "#F36E57" },
            }}
          >
            Read more
          </Button>
        </Grid>
      </Grid>
    </section>
  );
});
export default Benefits;
