import { Box, Button, Grid, Typography } from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

const Pricing = forwardRef((props, linkRef) => {
  const navigate = useNavigate();
  const {ref: pricing1Ref, inView: price1IsVisible} = useInView({triggerOnce: true, threshold: 0.5,});
  const {ref: pricing2Ref, inView: price2IsVisible} = useInView({triggerOnce: true, threshold: 0.5,});
  
  return (
    <section>
      <Grid
        container
        xs={12}
        sx={{
          backgroundImage: "url(./assets/images/pricing-bg-1.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        position="relative"
        padding={{xs:'0 20px', md:"0 120px"}}
      >
        <img
          src="./assets/images/pricing-bg-4.png"
          style={{ position: "absolute", width:"clamp(50px, 10vw, 200px)", top: "0", right: "0" }}
        />
        <img
          src="./assets/images/pricing-bg-3.png"
          
          style={{ position: "absolute", width:"clamp(50px, 5vw, 92px)", top: "100px", left: "5%" }}
        />
        <Box  
        ref={pricing1Ref}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <div style={{position:'relative', width:'clamp(300px, 50vw, 900px)'}}>
          <img
            id={`${price1IsVisible ? "pricing-bg-anm-1" : ""}`}
            className="pricing-bg-anm"
            src="./assets/images/pricing-bg-2.png"
          />
          <img
            id={`${price1IsVisible ? "pricing-bg-anm-2" : ""}`}
            className="pricing-bg-anm"
            src="./assets/images/pricing-bg-5.png"
          />
          <img
            id={`${price1IsVisible ? "pricing-bg-anm-3" : ""}`}
            className="pricing-bg-anm"
            src="./assets/images/pricing-bg-6.png"
          />
          <img
            id={`${price1IsVisible ? "pricing-bg-anm-4" : ""}`}
            className="pricing-bg-anm"
            src="./assets/images/pricing-bg-7.png"
          />
          <img
            id={`${price1IsVisible ? "pricing-bg-anm-5" : ""}`}
            className="pricing-bg-anm"
            src="./assets/images/pricing-bg-8.png"
          />
          </div>
        </Box>
        <Box ref={linkRef}
          padding={{xs:'5vh 0', md:"10vh 20vw"}}
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={{xs:'20vh', md:'500px'}}
        >
          <Typography
            className="border-bottom-centered font"
            textAlign="center"
            fontSize="clamp(1.2rem, 3vw, 3rem)"
            fontWeight="700"
          >
            Why Would an Agent allow a Proxy Agent to show a property to their
            client
          </Typography>
          <Button
            onClick={() => navigate("/pricing-detail")}
            variant="contained"
            className="font"
            sx={{
              padding: {xs:'7px 20px', md:"7px 70px"},
              mt: "3vh",
              borderRadius: "10px",
              bgcolor: "#EC2C6D",
              textTransform: "none",
              color: "white",
              fontSize:"clamp(1rem, 3vw, 2.5rem)",
              fontWeight: "600",
              "&:hover": { backgroundColor: "#F36E57" },
            }}
          >
            Read more
          </Button>
        </Box>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding='3vh 0'
        >
          <Typography
            className="border-bottom font"
            maxWidth="650px"
            fontSize="clamp(1.2rem, 3vw, 3rem)"
            fontWeight="700"
          >
            How much does an Agent need to pay to use our Services?
          </Typography>
          <Typography
          className="font"
            maxWidth="825px"
            fontSize="clamp(1rem, 3vw, 1.4rem)"
            textAlign="justify"
            fontWeight="400"
            lineHeight="normal"
            pt="3vh"
          >
            The price for showing anywhere is a <span style={{color:'#EC2C6D', fontWeight:'600', fontSize:'clamp(1.2rem, 3vw, 1.8rem)'}}>flat &#36;100</span>, but if you are
            requesting a proxy agent for more than one showing then the
            subsequent showings are priced at <span style={{color:'#EC2C6D', fontWeight:'600', fontSize:'clamp(1.2rem, 3vw, 1.8rem)'}}>&#36;50 each</span>, provided the same
            ProxyAgent accepts all showing requests.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} display='flex' justifyContent='center' alignItems="center" padding='3vh 0'>
        <div ref={pricing2Ref} style={{position:'relative', height:'clamp(300px, 10vh, 400px)', width:'clamp(300px, 50vw, 400px)'}}>
          <img
            id={`${price2IsVisible ? "pricing-second-bg-anm-1" : ""}`}
            className="pricing-second-bg-anm"
            src="./assets/images/pricing-second-bg-1.png"
          />
          <img
            id={`${price2IsVisible ? "pricing-second-bg-anm-2" : ""}`}
            className="pricing-second-bg-anm"
            src="./assets/images/pricing-second-bg-2.png"
          />
          <img
            id={`${price2IsVisible ? "pricing-second-bg-anm-3" : ""}`}
            className="pricing-second-bg-anm"
            src="./assets/images/pricing-second-bg-3.png"
          />
          <img
            id={`${price2IsVisible ? "pricing-second-bg-anm-4" : ""}`}
            className="pricing-second-bg-anm"
            src="./assets/images/pricing-second-bg-4.png"
          />
          <img
            id={`${price2IsVisible ? "pricing-second-bg-anm-5" : ""}`}
            className="pricing-second-bg-anm"
            src="./assets/images/pricing-second-bg-5.png"
          />
          <img
            id={`${price2IsVisible ? "pricing-second-bg-anm-6" : ""}`}
            className="pricing-second-bg-anm"
            src="./assets/images/pricing-second-bg-6.png"
          />
          <img
            id={`${price2IsVisible ? "pricing-second-bg-anm-7" : ""}`}
            className="pricing-second-bg-anm"
            src="./assets/images/pricing-second-bg-7.png"
          />
          <img
            id={`${price2IsVisible ? "pricing-second-bg-anm-8" : ""}`}
            className="pricing-second-bg-anm"
            src="./assets/images/pricing-second-bg-8.png"
          />
          </div>
        </Grid>
      </Grid>
    </section>
  );
});
export default Pricing;
