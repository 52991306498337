import { Box, Button, Grid, Typography } from "@mui/material";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <Grid container padding={{ xs: "3vh 50px", md: "3vh 0" }} bgcolor="#EC2C6D">
      <Grid item xs={12} md={3} padding="2vh 2vw">
        <img
          src="./assets/images/footer-logo.png"
          style={{ maxWidth: "317px" }}
        />
        <Box
          width="200px"
          display="flex"
          justifyContent="space-between"
          padding="3vh 0"
        >
          <FacebookOutlinedIcon sx={{ color: "white", fontSize: "50px" }} />
          <InstagramIcon sx={{ color: "white", fontSize: "50px" }} />
          <TwitterIcon sx={{ color: "white", fontSize: "50px" }} />
        </Box>
        <Typography
          className="font"
          color="white"
          fontSize="clamp(1.2rem, 2vw, 2rem)"
          fontWeight="600"
          letterSpacing="0.12px"
        >
          {/* Ahmad@agentproxy.ca */}
          @AgentProxy Inc.
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} padding="3vh 2vw">
        <Typography
          className="font"
          color="white"
          fontSize="clamp(1.2rem, 2vw, 2.5rem)"
          fontWeight="700"
        >
          Contact
        </Typography>

        <Typography
          className="font"
          mt="2vh"
          color="white"
          fontSize="clamp(1.2rem, 2vw, 2rem)"
          fontWeight="700"
        >
          289 412 1093 - Office
          <br />
          647 987 8862 - Cell
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} padding="3vh 2vw">
        <Typography
          className="font"
          color="white"
          fontSize="clamp(1.2rem, 2vw, 2.5rem)"
          fontWeight="700"
        >
          Office
        </Typography>

        <Typography
          className="font"
          mt="2vh"
          color="white"
          fontSize="clamp(1.2rem, 2vw, 2rem)"
          fontWeight="700"
        >
          AgentProxy Head Office
        </Typography>
        <Typography
          className="font"
          mt="1vh"
          color="white"
          fontSize="clamp(1.2rem, 2vw, 2rem)"
          fontWeight="600"
        >
          416 Kincardine Terrace, Milton ON L9T 8E9
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} padding="3vh 2vw">
        <Typography
          className="font"
          color="white"
          fontSize="clamp(1.2rem, 2vw, 2.5rem)"
          fontWeight="600"
        >
          Discover
        </Typography>
        <Box
          maxWidth="300px"
          display="flex"
          justifyContent="space-between"
          mt="2vh"
        >
          <a href="https://play.google.com/store/apps/details?id=pk.digit.agentproxy">
            <img
              src="./assets/images/download-playstore.png"
              style={{ maxWidth: "145px" }}
            />
          </a>
          <a href="https://apps.apple.com/pk/app/agentproxy/id6478040222">
            <img
              src="./assets/images/download-apple-store.png"
              style={{ maxWidth: "145px" }}
            />
          </a>
        </Box>
        <Button
          onClick={() => navigate("/privacy")}
          sx={{
            textTransform: "none",
          }}
        >
          <Typography
            className="font"
            mt="1vh"
            color="white"
            fontSize="clamp(1.2rem, 2vw, 2rem)"
            fontWeight="600"
          >
            Privacy Policy
          </Typography>
        </Button>
        <Button
          onClick={() => navigate("/terms")}
          sx={{
            textTransform: "none",
          }}
        >
          <Typography
            className="font"
            mt="1vh"
            color="white"
            fontSize="clamp(1.2rem, 2vw, 2rem)"
            fontWeight="600"
          >
            Terms & Conditions
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} borderTop="1px solid #fff">
        <Typography
          className="font"
          mt="3vh"
          color="#fff"
          textAlign="center"
          fontSize="clamp(0.8rem, 1vw, 1.2rem)"
          fontWeight="600"
        >
          Website Developed & Maintain by: <a href="http://digit.com.pk/" target="blank" style={{textDecoration:'none'}}><span style={{color:"#231F20"}}>DIG</span><span style={{color:"#8A171A"}}>IT</span> <span style={{color:'#241E20'}}>Pakistan</span></a>
        </Typography>
      </Grid>
    </Grid>
  );
}
