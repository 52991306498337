import { Box } from "@mui/material";
import "./App.css";
import "@fontsource/mulish";
import Navbar from "./ui components/Navbar";
import Ourstory from "./pages/Ourstory";
import Benefits from "./pages/Benefits";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Footer from "./ui components/Footer";
import { useRef } from "react";
import Main from "./pages/Main";
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import BenefitsDetail from "./pages/BenefitsDetail";
import PricingDetail from "./pages/PricingDetail";
import FormName from "./pages/FormName";
import FormEmail from "./pages/FormEmail";
import FormBrokerage from "./pages/FormBrokerage";
import FormNumber from "./pages/FormNumber";
import Congratulations from "./pages/Congratulations";
import ContextProvider from "./pages/ContextProvider";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
function App() {

  return (
    <ContextProvider>
    <Router>
      <Routes>
        <Route exact path="/" element={<Main/>}></Route>
        <Route exact path="/benefits-detail" element={<BenefitsDetail/>}></Route>
        <Route exact path="/pricing-detail" element={<PricingDetail/>}></Route>
        <Route exact path="/form-name" element={<FormName/>}></Route>
        <Route exact path="/form-email" element={<FormEmail/>}></Route>
        <Route exact path="/form-brokerage" element={<FormBrokerage/>}></Route>
        <Route exact path="/form-number" element={<FormNumber/>}></Route>
        <Route exact path="/congratulations" element={<Congratulations/>}></Route>
        <Route exact path="/privacy" element={<Privacy/>}></Route>
        <Route exact path="/terms" element={<Terms/>}></Route>
      </Routes>
    </Router>
    </ContextProvider>
  );
}

export default App;
