/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import BouncingParagraph from "./BouncingParagraph";

function Paragraphs() {
  const paragraphs = [
    "Inconvenience of showings",
    "Long travel times",
    "Fuel costs",
    "Sporadic Income",
    "Giving up Deals to other Realtors",
    "High Carbon Footprint",
    "Geographical limitations"
  ];
  const [selectedParagraph, setSelectedParagraph] = useState("");
  const [paragraphIndex, setParagraphIndex] = useState("");

  const handleParagraphs = () => {
    if (
      selectedParagraph === "" &&
      paragraphIndex === "" &&
      paragraphs.length > 0
    ) {
      setSelectedParagraph(paragraphs[0]);
      setParagraphIndex(0);
    } else if (paragraphIndex === paragraphs.length - 1) {
      setSelectedParagraph(paragraphs[0]);
      setParagraphIndex(0);
    } else if (selectedParagraph !== "" && paragraphIndex !== "") {
      setSelectedParagraph(paragraphs[paragraphIndex + 1]);
      setParagraphIndex(paragraphIndex + 1);
    }
  };

  useEffect(() => {
    handleParagraphs();
  }, []);

  return (
    <BouncingParagraph
      paragraph={selectedParagraph}
      handleParagraphs={handleParagraphs}
    />
  );
}

export default Paragraphs;
