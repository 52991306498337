import { Button, Grid, Typography } from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Paragraphs from "../components/Paragraphs";
import { useInView } from "react-intersection-observer";

const Home = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const { ref: homeRef, inView: homeIsVisible } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  return (
    <section ref={ref}>
      <Grid container padding={{ xs: "0 50px", md: "0 120px" }}>
        <Grid item xs={12} md={6}>
          <Typography
          className="font"
            style={{ fontSize: `clamp(1.2rem, 3vw, 3rem)` }}
            fontWeight="700"
            maxWidth="530px"
            pt="5vh"
            lineHeight="normal"
          >
            Reduce Carbon footprint & Increase your Sales footprint, the{" "}
            <span
              style={{
                color: "#EC2C6D",
                fontSize: "clamp(1.2rem, 3vw, 3rem)",
                fontWeight: "900",
                letterSpacing: "0.5px",
              }}
            >
              Agent
            </span>
            <span
              style={{
                color: "#452667",
                fontSize: `clamp(1.2rem, 3vw, 3rem)`,
                fontWeight: "900",
                letterSpacing: "0.5px",
              }}
            >
              Proxy
            </span>{" "}
            way.
          </Typography>
          <Typography
          className="font"
            style={{ fontSize: `clamp(1.2rem, 3vw, 3rem)` }}
            fontWeight="700"
            maxWidth="530px"
            pt="2vh"
            lineHeight="normal"
          >
            Say goodbye to
          </Typography>
          <Typography
          className="font"
            style={{ fontSize: `clamp(1.2rem, 3vw, 3rem)` }}
            fontWeight="700"
            maxWidth="530px"
            pt="2vh"
            lineHeight="normal"
          >
            <Paragraphs />
          </Typography>

          <Button
          
          className="font"
            onClick={() => navigate("/form-name")}
            variant="contained"
            sx={{
              padding: "10px 10px 7px",
              mt: "5vh",
              borderRadius: "10px",
              bgcolor: "#EC2C6D",
              textTransform: "none",
              color: "white",
              fontWeight: "600",
              "&:hover": { backgroundColor: "#F36E57" },
            }}
            style={{ fontSize: `clamp(1rem, 2vw, 1.8rem)` }}
          >
            Join Agent Waitlist
          </Button>
        </Grid>
        <Grid item xs={12} md={6} height="50vh" position="relative">
          <img
            ref={homeRef}
            id={`${homeIsVisible ? "banner-anm-img1" : ""}`}
            className="banner-anm-img"
            src="./assets/images/phone.png"
          />
          <img
            id={`${homeIsVisible ? "banner-anm-img2" : ""}`}
            className="banner-anm-img"
            src="./assets/images/road.png"
          />
          <img
            id={`${homeIsVisible ? "banner-anm-img3" : ""}`}
            className="banner-anm-img"
            src="./assets/images/road-line.png"
          />
          <img
            id={`${homeIsVisible ? "banner-anm-img4" : ""}`}
            className="banner-anm-img"
            src="./assets/images/buildings.png"
          />
          <img
            id={`${homeIsVisible ? "banner-anm-img5" : ""}`}
            className="banner-anm-img"
            src="./assets/images/location.png"
          />
          <img
            id={`${homeIsVisible ? "banner-anm-img6" : ""}`}
            className="banner-anm-img"
            src="./assets/images/circles.png"
          />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <img
            src="./assets/images/laptop.png"
            style={{ width: `clamp(300px, 35vw, 800px)` }}
          />
          <iframe
          id="video-link"
          style={{position:'absolute', top:'8%', left:'50%', transform:'translateX(-50%)'}}
            src="https://www.youtube.com/embed/9uicLC1-kaA?si=2BbiS949KVU4_4Rk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Grid>
      </Grid>
    </section>
  );
});
export default Home;
