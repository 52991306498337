import React, { useEffect } from "react";
import Footer from "../ui components/Footer";
import { Box, Grid, Typography } from "@mui/material";
import Navbar from "../ui components/Navbar";
export default function BenefitsDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar currentPage="benefits-detail" />
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <img
          src="./assets/images/benefits-bg-1.png"
          style={{
            position: "absolute",
            bottom: "10%",
            right: "5%",
            maxHeight: "150px",
          }}
        />
        <img
          src="./assets/images/pricing-bg-3.png"
          style={{
            position: "absolute",
            width: "clamp(50px, 5vw, 92px)",
            top: "25%",
            left: "5%",
          }}
        />
        <Box
          width="70vw"
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxSizing="border-box"
        >
          <div
            style={{ position: "relative", width: "clamp(300px, 50vw, 900px)" }}
          >
            <img
              id="benefits-second-bg-anm-1"
              className="benefits-second-bg-anm"
              src="./assets/images/benefits-second-bg-1.png"
            />
            <img
              id="benefits-second-bg-anm-2"
              className="benefits-second-bg-anm"
              src="./assets/images/benefits-second-bg-2.png"
            />
            <img
              id="benefits-second-bg-anm-3"
              className="benefits-second-bg-anm"
              src="./assets/images/benefits-second-bg-3.png"
            />
            <img
              id="benefits-second-bg-anm-4"
              className="benefits-second-bg-anm"
              src="./assets/images/benefits-second-bg-4.png"
            />
            <img
              id="benefits-second-bg-anm-5"
              className="benefits-second-bg-anm"
              src="./assets/images/benefits-second-bg-5.png"
            />
            <img
              id="benefits-second-bg-anm-6"
              className="benefits-second-bg-anm"
              src="./assets/images/benefits-second-bg-6.png"
            />
            <img
              id="benefits-second-bg-anm-7"
              className="benefits-second-bg-anm"
              src="./assets/images/benefits-second-bg-2.png"
            />
            <img
              id="benefits-second-bg-anm-8"
              className="benefits-second-bg-anm"
              src="./assets/images/benefits-second-bg-3.png"
            />
          </div>
        </Box>
        <Typography
          className="border-bottom-centered font"
          textAlign="center"
          fontSize="clamp(1.2rem, 3vw, 3rem)"
          fontWeight="700"
          mt={{ xs: "25vh", md: "600px" }}
        >
          Benefits for paying the ProxyAgent
        </Typography>
        <Typography
          className="font"
          fontSize="24px"
          textAlign="justify"
          fontWeight="400"
          padding="5vh 13vw"
        >
          Real Estate Agents that live in a particular city or area are more
          familiarized with local community, demographics, amenities,
          neighborhoods, schools, hospitals, and overall, all things in close
          proximity to the property. Therefore, if a ProxyAgent gets to show
          another realtor’s client a property then they would be more informed
          on the specs of the property and can perhaps more easily bring and
          educate one of their own clients on the perks of the property.
          <br />
          <br />A ProxyAgent through our platform can make upwards of $40 per
          showing where we deduct 20% as a transaction fee per showing.
          Additionally, they also have the option to accept multiple showings
          that have been requested and make $20 per every subsequent showing
          accepted. However, if a new AgentProxy accepts 1 of the multiple
          showings then in that case they would still get paid $45.
        </Typography>
      </Grid>
      <Footer />
    </>
  );
}
