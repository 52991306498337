import React, { useEffect } from "react";
import Navbar from "../ui components/Navbar";
import Footer from "../ui components/Footer";
import { Box, Typography } from "@mui/material";
export default function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar currentPage='terms'/>
      <Box padding={{ xs: "10vh 50px", md: "20vh 15vw" }}>
        <Typography
          className="border-bottom-centered font"
          textAlign="center"
          fontSize="clamp(1.2rem, 3vw, 3rem)"
          fontWeight="700"
          mb={{ xs: "5vh", md: "7vh" }}
        >
          AGENTPROXY INC. TERMS OF USE
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>LAST UPDATED: 2024-01-19</b>
          
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>1. OVERVIEW</b>
          <br /><br/>
          These Terms of Use form a legally binding contract between AgentProxy
          Inc. ("we" or "us") and the people and companies (“Users” or “you”)
          that access and use our Mobile App AgentProxy located on both App
          store and Google Play, as well as any related website controlled by us
          (collectively, the "Website"). These Terms of Use apply to all
          services provided through our App and any other software application
          we operate, and all content, services, and products available at or
          through the App. It is very important that you read and fully
          understand these Terms of Use before using the App. By agreeing to
          these Terms of Use and using the App, you are entering into a legally
          binding contract which will impact your rights. By accessing any part
          of the App, you agree to be bound by these Terms of Use. You are also
          confirming that you have read and fully understood these Terms of Use.
          If you do not agree to or understand all of these Terms of Use, then
          you may not access the App or use any services available on the App.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>2. SERVICES AVAILABLE ON THE APP</b>
          <br /><br />
          Without restriction, we generally offer the following services through
          the App: Request a showing to be conducted on your behalf by another
          licensed agent of your brokerage that will oversee your client at a
          specified address during a designated date and time slot. By using the
          App, you are confirming that you have determined that the services are
          appropriate for your needs. We do not guarantee that these services
          meet your needs or that they are suitable for your specific purposes.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>3. TERMS OF USE</b>
          <br /><br />
          When you choose to create an account on our App you conform to our
          standards of engagement. You adhere to the scope of requesting and
          accepting a property showing on our platform for a designated address
          at a specific date and time. Our App does not facilitate the process
          of booking a showing but rather enables agents to collaborate together
          for a service and receive remuneration from their brokerage on our App
          for services provided. The word service constitutes an agreement only
          to the extent of a particular showing at a specific address, date, and
          time slot between 2 agents where the agent that accepts a showing
          request will fulfill all duties and obligations in a righteous and
          ethical manner without the intention of conducting malpractice or
          poaching the client of the original requesting agent. The accepting
          agent by accepting a showing request on our App acknowledges and
          assumes the risks and responsibilities of being an agent but only for
          the designated time slot, at a specific date and address, hence they
          will not conduct the follow-ups with the client as that is the sole
          responsibility of the original requesting agent with whom a client has
          signed a Buyer Representation Agreement. The requesting agent can also
          choose to keep their client’s information on our App confidential, but
          AgentProxy Inc. does not take any responsibility whatsoever for the
          sharing of client information externally or through our App. Failure
          to comply with the standards of engagement will lead to dispute
          resolution by broker of record, possible termination of account, and
          legal proceedings if deemed appropriate by both your applicable broker
          of record and AgentProxy Inc.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>4. YOUR ACCOUNT</b>
          <br /><br />
          To utilize the services we offer on our App, you give AgentProxy Inc.
          the consent to use the relevant information you choose to provide for
          the purpose of carrying out and processing all transactions. However,
          AgentProxy Inc. is not liable for loss or misuse of information in the
          occurrence of a breach.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>5. TERMINATION</b>
          <br /><br />
          You may cancel and terminate your Account at any time in accordance
          with the terms and policies posted on the App.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>6. LIMITATION OF LIABILITY</b>
          <br /><br />
          Except in a case where we are in violation of these Terms of Use, we
          will not be held liable for, and you hereby fully waive the right to
          claim for, any loss, injury, claim, liability or damages of any kind
          resulting in any way from use of the App. AgentProxy Inc. is not
          liable for any negligence conducted by an agent during a showing. Your
          use of the App is at your sole risk. The App is provided on an “as is”
          and “as available” basis without any warranty or condition, express,
          implied or statutory. We do not warrant that your use of the App will
          be uninterrupted, secure or error-free. In no event will we have any
          liability to you or any third party for any lost profits or revenues
          or for any indirect, special, incidental, consequential, or punitive
          damages however caused, whether in contract, tort, or otherwise, and
          whether or not you or the third party have been advised of the
          possibility of such damages. In the event the foregoing paragraph, or
          any part thereof, is void under applicable law, this paragraph, or
          such part thereof, shall be inapplicable.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>7. INDEMNIFICATION</b>
          <br /><br />
          You agree to indemnify and hold harmless AgentProxy Inc., including
          our officers, directors, shareholders, employees and agents, from and
          against any and all claims and expenses, including legal fees and
          disbursements, which are made against us and arise out of your use of
          the App, including but not limited to your violation of any term of
          these Terms of Use or any other policy posted on the App.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>8. SECURITY BREACHES</b>
          <br /><br />
          In order to protect your security, it is your sole responsibility to
          ensure that all usernames and passwords used to access the App are
          kept secure and confidential. You must immediately notify us of any
          unauthorized use of your account, including the unauthorized use of
          your password, or any other breach of security. We will investigate
          any breach of security on the App that we determine in our sole
          discretion to be serious in nature, but we will not be held
          responsible or liable in any manner for breaches of security or any
          unauthorized access to your account however arising.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>9. WARRANTIES AND REPRESENTATIONS</b>
          <br /><br />
          We hereby disclaim all warranties of any kind, whether express,
          implied, or statutory, including but not limited to implied warranties
          as to merchantability or fitness for a particular purpose as they
          relate to the App.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>10. COMPLIANCE WITH LAWS</b>
          <br /><br />
          You represent and warrant that: i. You have the authority to bind
          yourself to these Terms of Use; ii. Your use of the App will be solely
          for purposes that are permitted by these Terms of Use; iii. Your use
          of the App will not infringe or misappropriate the confidentiality or
          intellectual property rights of any User or third party; and iv. Your
          use of the App will comply with all local, provincial and federal
          laws, rules and regulations, and with all policies posted on the App.
          You must only use the App for your own lawful purposes, in accordance
          with these Terms of Use and any notice, policy or condition posted on
          the App. You may use the App on behalf of others or in order to
          provide services to others but if you do so you must ensure that you
          are authorized to do so and that all persons for whom or to whom
          services are provided comply with and accept all these Terms of Use.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>11. USER RESTRICTIONS</b>
          <br /><br />
          Only Users Registered with RECO and deemed as active licensed agents
          in Ontario are authorized to use the App.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>12. GOVERNING LAW AND DISPUTE RESOLUTION</b>
          <br /><br />
          These Terms of Use and any access to or use of the Website shall be
          governed by, and construed in accordance with, the laws in force in
          the Province of Ontario. If any claim, dispute or controversy occurs
          between AgentProxy Inc. and a User relating to the interpretation or
          implementation of any of the provisions of these Terms of Use, such
          dispute shall be resolved by private, confidential and binding
          arbitration. Such arbitration shall be conducted by a single
          arbitrator. The arbitrator shall be appointed by agreement of the
          parties or, in the absence of an agreement, such arbitrator shall be
          appointed by a judge upon the application of either the User or
          AgentProxy Inc. Arbitration shall be held in the Province of Ontario,
          unless otherwise agreed by the parties. The arbitration procedure to
          be followed shall be agreed by the parties or, in absence of an
          agreement, determined by the arbitrator. The arbitration shall proceed
          in accordance with the provisions of the Arbitration Act, 1991, SO
          1991, c 17. Subject to any right of appeal, the decision arrived at by
          the arbitrator shall be final and binding. Judgment upon the award
          rendered by the arbitrator may be entered in any court having
          jurisdiction.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>13. WAIVER OF CLASS ACTION</b>
          <br /><br />
          By using the App, you agree to resolve any claim or dispute arising
          between you and us on an individual basis, rather than addressing such
          claim or dispute as part of a group or class. You hereby waive any
          right you may have to commence or participate in any class action
          lawsuit commenced against AgentProxy Inc. or its affiliates related to
          any claim, dispute or controversy arising from your use of the App.
          Where applicable, you hereby agree to opt out of any class proceeding
          against AgentProxy Inc. otherwise commenced. The above waiver shall
          not apply to claims or disputes arising under consumer protection
          legislation or any other claim or dispute where a waiver of class
          action lawsuits is unenforceable at law.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>14. GENERAL TERMS</b>
          <br /><br />
          <ol type="a">
            <li>
              AMENDING THESE TERMS OF USE These Terms of Use may be updated and
              amended from time to time. We reserve the right to change these
              Terms of Use at any time, and any amended Terms of Use are
              effective upon posting to the App. We will make efforts to
              communicate any changes to these Terms of Use we deem material, in
              our sole discretion, via email or notifications on the App. Your
              continued use of the App will be deemed to be immediate and
              unconditional acceptance of any amended Terms of Use, whether or
              not we deemed the amendments to be material.
            </li>{" "}
            <li>
              {" "}
              ASSIGNMENT We may assign or delegate these Terms of Use, in whole
              or in part, to any person or entity at any time with or without
              your consent and without prior notice to you. Users may not assign
              or delegate any rights or obligations under these Terms of Use,
              without our prior written consent, and any unauthorized assignment
              and delegation is void.
            </li>{" "}
            <li>
              NO WAIVER No waiver of a provision, right or remedy of this
              Agreement shall operate as a waiver of any other provision, right
              or remedy or the same provision, right or remedy on a future
              occasion.
            </li>{" "}
            <li>
              {" "}
              NO AGENCY The parties to these Terms of Use are independent
              contractors and are not partners or agents. AgentProxy Inc. has no
              fiduciary obligations or professional obligations whatsoever to
              you arising from these Terms of Use or your use of the App.
            </li>{" "}
            <li>
              SEVERABILITY In the event that any provision or part of this
              Agreement is found to be void or invalid by a court of law, the
              remaining provisions, or parts thereof, shall be and remain in
              full force and effect.
            </li>
            <li>
              ENTIRE AGREEMENT These Terms of Use, in conjunction with all
              policies and guidelines available on the App (including but not
              limited to any Privacy Policy), incorporated by reference,
              constitute the entire agreement between you and AgentProxy Inc and
              supersede all prior communications, agreements and understandings,
              written or oral, with respect to the subject matter of these Terms
              of Use.
            </li>
          </ol>
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>15. QUESTIONS ON THESE TERMS OF USE</b>
          <br /><br />
          We welcome you to contact us with any questions on these Terms of Use.
          You can send your questions regarding these Terms of Use to the
          following email address: ahmad@agentproxy.ca
          <br />
          <br />
          </Typography>
          <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>16. CONFIRMATION OF AGREEMENT TO TERMS OF USE</b>
          <br /><br />
          BY PROCEEDING TO USE THE APP, YOU ACKNOWLEDGE THAT YOU HAVE READ,
          UNDERSTOOD AND AGREE TO BE BOUND BY THESE TERMS OF USE AND ANY
          POLICIES AND NOTICES POSTED ON THE APP.
        </Typography>
      </Box>
      <Footer />
    </>
  );
}
