import { Grid, Typography, containerClasses } from "@mui/material";
import React, { forwardRef } from "react";
import { useInView } from "react-intersection-observer";

const Contact = forwardRef ((props, ref) => {
  
  const {ref: contactRef, inView: contactIsVisible} = useInView({triggerOnce:true, threshold:0.5})
  return (
    <section ref={ref}>

    <Grid container padding={{xs:'5vh 50px', md:"5vh 120px"}} mb='5vh' position='relative'>
      <Grid item xs={6} display='flex' justifyContent='center' alignItems="center" padding='3vh 0'>
      <div ref={contactRef} style={{position:'relative', height:'clamp(200px, 30vh, 400px)', width:'clamp(300px, 50vh, 400px)'}}>
        <img id={`${contactIsVisible ? "contact-bg-anm-1" : ""}`} className="contact-bg-anm" src="./assets/images/contact-bg-2.png" />
        <img id={`${contactIsVisible ? "contact-bg-anm-2" : ""}`} className="contact-bg-anm" src="./assets/images/contact-bg-1.png" />
        <img id={`${contactIsVisible ? "contact-bg-anm-3" : ""}`} className="contact-bg-anm" src="./assets/images/contact-bg-3.png" />
        <img id={`${contactIsVisible ? "contact-bg-anm-4" : ""}`} className="contact-bg-anm" src="./assets/images/contact-bg-4.png" />
        <img id={`${contactIsVisible ? "contact-bg-anm-5" : ""}`} className="contact-bg-anm" src="./assets/images/contact-bg-7.png" />
        <img id={`${contactIsVisible ? "contact-bg-anm-6" : ""}`} className="contact-bg-anm" src="./assets/images/contact-bg-5.png" />
        <img id={`${contactIsVisible ? "contact-bg-anm-7" : ""}`} className="contact-bg-anm" src="./assets/images/contact-bg-6.png" />
        <img id={`${contactIsVisible ? "contact-bg-anm-8" : ""}`} className="contact-bg-anm" src="./assets/images/contact-bg-8.png" />
        </div>
      </Grid>
      <Grid item xs={12} md={6} display='flex' flexDirection='column' justifyContent='center' padding='3vh 0'>
        <Typography
            className="border-bottom font"
          maxWidth="445px"
          fontSize="clamp(1.2rem, 3vw, 3rem)"
          fontFamily="Mulish"
          fontWeight="700"
        >
          How is AgentProxy <br />
          sustainable
        </Typography>
        <Typography
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          fontFamily="Mulish"
          lineHeight="normal"
          pt="3vh"
        >
          The Real Estate industry is noted as the 2nd largest emitter of carbon
          emissions globally and a substantial portion of it stems from the
          nature of the business which entails having to drive to multiple
          locations for the purpose of showings. <br/><br/> At AgentProxy our goal is
          simple to reduce emissions by enabling local agents of a city to
          conduct showings on behalf of other real estate agents.
        </Typography>
      </Grid>
      <img src="./assets/images/benefits-bg-1.png"
              style={{ position: "absolute", width:"clamp(50px, 5vw, 92px)", bottom: "0", left: "5%", zIndex:'-1' }}/>
    </Grid>
    </section>
  );
})
export default Contact;
