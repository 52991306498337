import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../ui components/Footer";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Navbar from "../ui components/Navbar";
import { FormContext } from "./ContextProvider";
export default function FormName(){
    const [userName, setUserName] = useState("")
    const [error, setError] = useState(false)
    const {getClientEmail} = useContext(FormContext)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const navigate = useNavigate()
    const handleNameChange = (event) => {
      setUserName(event.target.value);
      getClientEmail(event)
    };
    
    const handleRightButtonClick = () => {
      
      if(emailPattern.test(userName) == true){
        console.log("right email pattern")
      }
      else{
        alert("Type correct email")
      }
        if (userName.trim() !== '' && emailPattern.test(userName)) {
          // Only navigate if the TextField is not empty
          navigate('/form-brokerage');
        }
      };
    return(
        <>
        <Navbar currentPage = "form-email" />
        <Box padding={{xs:'10vh 50px', md:'20vh 15vw'}} height={{xs:'50vh', md:'70vh'}}>
        <Typography fontSize='32px' fontWeight='600' fontFamily='segoe UI' margin='0 0 5vh'>
            Whats your email 📬 ?
        </Typography>
        <TextField type="email" variant="standard" placeholder="Your email" name="client_email" fullWidth onChange={handleNameChange} required/>
        <div style={{display:'flex', justifyContent:'center', gap:5, marginTop:'30vh'}}>
        <Button
        onClick={()=> navigate("/form-name")}
        style={{
            display:'flex',
            justifyContent:'flex-start',
            borderRadius:'100px',
            backgroundColor:'#FF9A6C',
            color:'white',
        }}
        >
            <ArrowCircleLeftIcon/>
        </Button>
        <Button
        onClick={handleRightButtonClick}
        style={{
            display:'flex',
            justifyContent:'end',
            borderRadius:'100px',
            cursor: userName.trim() === '' ? 'not-allowed' : 'pointer',
            backgroundColor: userName.trim() === '' ? '#f0f0f0' : '#EC2C6D',
            color: userName.trim() === '' ? '#808080' : '#ffffff',
          }}
        >
            <ArrowCircleRightIcon/>
        </Button>
        </div>
        </Box>
        <Footer/>
        </>
    )
}