import { Box, Button, Grid, Typography } from "@mui/material";
import React, { forwardRef, useState } from "react";
import { useInView } from "react-intersection-observer";


const Ourstory = forwardRef((props, ref) => {

  const {ref: ourStory1Ref, inView: ourStory1IsVisible} = useInView({triggerOnce: true, threshold: 0.5,});
  const {ref: ourStory2Ref, inView: ourStory2IsVisible} = useInView({triggerOnce: true, threshold: 0.5,});
  return (
    <section ref={ref}>
      <Grid container padding={{ xs: "0 50px", md: "0 120px" }}>
        <Grid item xs={12} md={6} padding="3vh 0">
          <Typography
            className="border-bottom font"
            marginTop="5vh"
            fontSize="clamp(1.2rem, 3vw, 3rem)"
            fontWeight="700"
          >
            The Pain Point AgentProxy
            <br /> is solving ?
          </Typography>
          <Typography
          className="font"
            fontSize="clamp(1rem, 3vw, 1.4rem)"
            textAlign="justify"
            fontWeight="400"
            lineHeight="normal"
            pt="3vh"
          >
            The Real Estate Industry plays a pivotal role in the Canadian
            Economy, and with so many agents raising the standard of excellence
            in this regulated industry, a question that often arises is what are
            some of the problems that truly need solving. Off the bat, some of
            the biggest ones include the following :
          </Typography>
          <ul>
            <li className="readmore-list">
              <Typography
          className="font"
                fontSize="clamp(1rem, 3vw, 1.4rem)"
                textAlign="justify"
                fontWeight="400"
                lineHeight="normal"
              >
                Agents can be sick, busy, or on vacation, as a result conducting property showings can become a hassle
              </Typography>
            </li>
            <li className="readmore-list">
              <Typography
          className="font"
                fontSize="clamp(1rem, 3vw, 1.4rem)"
                textAlign="justify"
                fontWeight="400"
                lineHeight="normal"
              >
                Malpractices of providing lockbox codes to non-licensed agents, Buyers, family, or friends for the purpose of property showings
              </Typography>
            </li>
            <li className="readmore-list">
              <Typography
          className="font"
                fontSize="clamp(1rem, 3vw, 1.4rem)"
                textAlign="justify"
                fontWeight="400"
                lineHeight="normal"
              >
                Agents are known to have sporadic income and lack oppurtunites to generate additional steady income at their own convenience
              </Typography>
            </li>
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="3vh 0"
        >
          <div ref={ourStory1Ref}
            style={{
              position: "relative",
              height: "clamp(200px, 10vh, 400px)",
              width: "clamp(300px, 50vw, 400px)",
            }}
          >
            <img
            id={`${ourStory1IsVisible ? "our-story-bg-anm-1" : ""}`}
              className="our-story-bg-anm"
              src="./assets/images/our-story-bg-1.png"
            />
            <img
            id={`${ourStory1IsVisible ? "our-story-bg-anm-2" : ""}`}
              className="our-story-bg-anm"
              src="./assets/images/our-story-bg-2.png"
            />
            <img
            id={`${ourStory1IsVisible ? "our-story-bg-anm-3" : ""}`}
              className="our-story-bg-anm"
              src="./assets/images/our-story-bg-3.png"
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          padding="3vh 0"
        >
          <div ref={ourStory2Ref}
            style={{
              position: "relative",
              height: "clamp(300px, 10vh, 400px)",
              width: "clamp(300px, 50vw, 400px)",
            }}
          >
            <img
            
            id={`${ourStory2IsVisible ? "our-story-soceond-bg-anm-1" : ""}`}
              className="our-story-soceond-bg-anm"
              src="./assets/images/our-story-bg-9.png"
            />
            <img
            id={`${ourStory2IsVisible ? "our-story-soceond-bg-anm-2" : ""}`}
              className="our-story-soceond-bg-anm"
              src="./assets/images/our-story-bg-10.png"
            />
            <img
            id={`${ourStory2IsVisible ? "our-story-soceond-bg-anm-3" : ""}`}
              className="our-story-soceond-bg-anm"
              src="./assets/images/our-story-bg-11.png"
            />
            <img
            id={`${ourStory2IsVisible ? "our-story-soceond-bg-anm-4" : ""}`}
              className="our-story-soceond-bg-anm"
              src="./assets/images/our-story-bg-12.png"
            />
            <img
            id={`${ourStory2IsVisible ? "our-story-soceond-bg-anm-5" : ""}`}
              className="our-story-soceond-bg-anm"
              src="./assets/images/our-story-bg-13.png"
            />
            <img
            id={`${ourStory2IsVisible ? "our-story-soceond-bg-anm-6" : ""}`}
              className="our-story-soceond-bg-anm"
              src="./assets/images/our-story-bg-14.png"
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          padding="3vh 0"
        >
          <Typography
            className="border-bottom font"
            fontSize="clamp(1.2rem, 3vw, 3rem)"
            fontWeight="700"
          >
            We have a different
            <br /> perspective about Property Showings
          </Typography>
          <Typography
          className="font"
            fontSize="clamp(1rem, 3vw, 1.4rem)"
            textAlign="justify"
            fontWeight="400"
            lineHeight="normal"
            pt="3vh"
          >
            Real Estate Agents have to pay a monthly fee to their brokerage
            irrespective of whether they make a sale or not, and since the
            income cycle of agents is sporadic, oftentimes coming up with the
            funds for monthly survival can become a challenge. Hence, at
            AgentProxy our core purpose and objective is to enable agents who
            are new to the real estate game, do not have much business, and are
            in dire need to avail opportunities to make additional income at
            their own convenience.
          </Typography>
        </Grid>
      </Grid>
    </section>
  );
});
export default Ourstory;
