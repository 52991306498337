import React, { createContext, useRef, useState } from 'react';
import { db } from '../firebase-config';
import { collection, addDoc } from 'firebase/firestore';
import emailjs from '@emailjs/browser';

export const FormContext = createContext(null);

function ContextProvider({ children }) {
  const [cName, setClientName] = useState('');
  const [bName, setBrokerageName] = useState('');
  const [cEmail, setClientEmail] = useState('');
  const [cNumber, setClientNumber] = useState('');
  const form = useRef(null); // Initialize useRef with null

  // email sending code
  const sendEmail = () => {
    emailjs.sendForm(
      process.env.REACT_APP_YOUR_SERVICE_ID,
      process.env.REACT_APP_YOUR_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_YOUR_PUBLIC_KEY
    )
      .then((result) => {
        console.log(result.text, "kya ye chala?");
      })
      .catch((error) => {
        console.log(error.text,"ya phir ye");
      });
  };
  // firebase database code
  const collectionRef = collection(db, 'leads');

  const getClientName = (e) => {
    setClientName(e.target.value);
  };

  const getBrokerageName = (e) => {
    setBrokerageName(e.target.value);
  };

  const getClientEmail = (e) => {
    setClientEmail(e.target.value);
  };

  const getClientNumber = (e) => {
    setClientNumber(e.target.value);
  };

  const createClientData = async () => {
    try {
      await addDoc(collectionRef, {
        clientName: cName,
        brokerageName: bName,
        clientEmail: cEmail,
        clientNumber: cNumber,
      });
    } catch (error) {
      console.error('Error creating client data:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const contactUs = () => {
    sendEmail();
    createClientData();
  };

  return (
    <FormContext.Provider
      value={{
        getClientName,
        getBrokerageName,
        getClientEmail,
        getClientNumber,
        createClientData,
        contactUs,
      }}
    >
      {children}
      <form ref={form} onSubmit={contactUs} style={{display:'none'}}>
        <input type="text" value={cName} name="client_name" onChange={getClientName} />
        <input type="email" value={cEmail} name="client_email" onChange={getClientEmail} />
        <input type="text" value={bName} name="broker_name" onChange={getBrokerageName} />
        <input type="number" value={cNumber} name="client_number" onChange={getClientNumber} />
        <input type="submit" />
      </form>
    </FormContext.Provider>
  );
}

export default ContextProvider;
