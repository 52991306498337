import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Footer from "../ui components/Footer";
import Navbar from "../ui components/Navbar";

export default function PricingDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar currentPage="pricing-detail" />
      <Grid
        container
        xs={12}
        sx={{
          backgroundImage: "url(./assets/images/pricing-bg-1.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        position="relative"
        padding={{ xs: "0 50px", md: "0 120px" }}
      >
        <img
          src="./assets/images/pricing-bg-4.png"
          style={{
            position: "absolute",
            width: "clamp(50px, 10vw, 200px)",
            top: "0",
            right: "0",
          }}
        />
        <img
          src="./assets/images/pricing-bg-3.png"
          style={{
            position: "absolute",
            width: "clamp(50px, 5vw, 92px)",
            top: "100px",
            left: "5%",
          }}
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <div
            style={{ position: "relative", width: "clamp(300px, 50vw, 900px)" }}
          >
            <img
              id="pricing-bg-anm-1"
              className="pricing-bg-anm"
              src="./assets/images/pricing-bg-2.png"
            />
            <img
              id="pricing-bg-anm-2"
              className="pricing-bg-anm"
              src="./assets/images/pricing-bg-5.png"
            />
            <img
              id="pricing-bg-anm-3"
              className="pricing-bg-anm"
              src="./assets/images/pricing-bg-6.png"
            />
            <img
              id="pricing-bg-anm-4"
              className="pricing-bg-anm"
              src="./assets/images/pricing-bg-7.png"
            />
            <img
              id="pricing-bg-anm-5"
              className="pricing-bg-anm"
              src="./assets/images/pricing-bg-8.png"
            />
          </div>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={{ xs: "25vh", md: "500px" }}
        >
          <Typography
            className="border-bottom-centered"
            textAlign="center"
            fontSize="clamp(1.2rem, 3vw, 3rem)"
            fontWeight="700"
          >
            Why Would an Agent allow a Proxy Agent to show a property to their
            client
          </Typography>
          <Typography
            className="font"
            width="100%"
            fontSize="clamp(1rem, 3vw, 1.4rem)"
            textAlign="justify"
            fontWeight="400"
            padding="5vh 0"
          >
            The Canvas of Real Estate is vast since a registered agent with OREA
            can sell across Ontario, however, many agents do not want to take
            the burden of traveling long distances, incurring fuel costs, and
            going around showing properties to their clients because the client
            might not even be pre-approved for a mortgage or rather might just
            change their mind about buying after being shown many properties.
            Hence, a common practice that agents mostly resort to includes
            passing off these clients to another agent and agreeing upon a
            percentage or referral split of the entire commission amount,
            however, what if I was to tell you that with AgentProxy you can keep
            your entire commission to yourself and can simultaneously provide a
            high quality experience for your clients by enabling local agents to
            do the showings who are usually more familiarized and better
            informed about local neighborhoods and demographics of the cities
            they live in.
            <br /> <br />
            By virtue of working with AgentProxy an agent can increase their
            sales footprint, reduce carbon footprint, and take advantage of the
            service on an as needed basis. Furthermore, we live in a time where
            the pandemic has shifted brokerages to become fully digitally
            enabled and as a result all agents can conduct their businesses on
            the go or remotely without any physical presence, and that’s where
            AgentProxy is using technology to make the showings process more
            efficient, whereby an agent can have their client see a property
            without them having to be there.
          </Typography>
        </Box>
      </Grid>
      <Footer />
    </>
  );
}
