import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

export default function Navbar({ handleScroll, currentPage }) {
  const navigate = useNavigate();
  return (
    <Grid
      container
      padding={{ xs: "3vh 50px 2vh", md: "3vh 120px 2vh" }}
      bgcolor="#FDF5EB"
      sx={{ opacity: "0.9" }}
    >
      <Grid item xs={5} display="flex" justifyContent="start">
        <Link to="/">
          <img src="./assets/images/logo.png" height="53px" />
        </Link>
      </Grid>
      <Grid
        item
        xs={7}
        display={{ xs: "none", md: "flex" }}
        justifyContent="space-between"
      >
        <Button
          onClick={() => {
            currentPage == "form-name" ||
            currentPage == "form-email" ||
            currentPage == "form-brokerage" ||
            currentPage == "form-number" ||
            currentPage == "congratulations" ||
            currentPage == "benefits-detail" ||
            currentPage == "pricing-detail" ||
            currentPage == "privacy" ||
            currentPage == "terms"
              ? navigate("/")
              : handleScroll("home");
          }}
          sx={{
            color: "#2E476B",
            fontSize: "24px",
            textTransform: "none",
            fontFamily: "Segoe UI",
            fontWeight: 600,
          }}
        >
          Home
        </Button>
        <Button
          onClick={() => {
            currentPage == "form-name" ||
            currentPage == "form-email" ||
            currentPage == "form-brokerage" ||
            currentPage == "form-number" ||
            currentPage == "congratulations" ||
            currentPage == "benefits-detail" ||
            currentPage == "pricing-detail" ||
            currentPage == "privacy" ||
            currentPage == "terms"
              ? navigate("/")
              : handleScroll("ourstory");
          }}
          sx={{
            color: "#2E476B",
            fontSize: "24px",
            textTransform: "none",
            fontFamily: "Segoe UI",
            fontWeight: 600,
          }}
        >
          Our Story
        </Button>
        <Button
          onClick={() => {
            currentPage == "form-name" ||
            currentPage == "form-email" ||
            currentPage == "form-brokerage" ||
            currentPage == "form-number" ||
            currentPage == "congratulations" ||
            currentPage == "benefits-detail" ||
            currentPage == "pricing-detail" ||
            currentPage == "privacy" ||
            currentPage == "terms"
              ? navigate("/")
              : handleScroll("benefits");
          }}
          sx={{
            color: "#2E476B",
            fontSize: "24px",
            textTransform: "none",
            fontFamily: "Segoe UI",
            fontWeight: 600,
          }}
        >
          Benefits
        </Button>
        <Button
          onClick={() => {
            currentPage == "form-name" ||
            currentPage == "form-email" ||
            currentPage == "form-brokerage" ||
            currentPage == "form-number" ||
            currentPage == "congratulations" ||
            currentPage == "benefits-detail" ||
            currentPage == "pricing-detail" ||
            currentPage == "privacy" ||
            currentPage == "terms"
              ? navigate("/")
              : handleScroll("pricing");
          }}
          sx={{
            color: "#2E476B",
            fontSize: "24px",
            textTransform: "none",
            fontFamily: "Segoe UI",
            fontWeight: 600,
          }}
        >
          Pricing
        </Button>
        <Button
          onClick={() => {
            currentPage == "form-name" ||
            currentPage == "form-email" ||
            currentPage == "form-brokerage" ||
            currentPage == "form-number" ||
            currentPage == "congratulations" ||
            currentPage == "benefits-detail" ||
            currentPage == "pricing-detail" ||
            currentPage == "privacy" ||
            currentPage == "terms"
              ? navigate("/")
              : handleScroll("contact");
          }}
          sx={{
            color: "#2E476B",
            fontSize: "24px",
            textTransform: "none",
            fontFamily: "Segoe UI",
            fontWeight: 600,
          }}
        >
          Contact
        </Button>
      </Grid>
    </Grid>
  );
}
