/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./textAnimation.css";

function BouncingParagraph({ paragraph, handleParagraphs }) {
  const [wordsArray, setWordsArray] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      if (wordsArray.length && currentWordIndex === "") {
        setCurrentWordIndex(0);
      } else if (currentWordIndex === wordsArray.length - 1) {
        setCurrentWordIndex("");
        handleParagraphs();
      } else if (wordsArray.length && currentWordIndex !== "") {
        setCurrentWordIndex(currentWordIndex + 1);
      }
    }, 1000 * 1); // Adjust the delay (2 seconds in this case)

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [currentWordIndex]);

  useEffect(() => {
    setWordsArray(paragraph.split(" "));
    setCurrentWordIndex(0);
  }, [paragraph]);

  return (
    <>
      <Stack direction="row" gap={0.5}>
        {wordsArray.map((word, index) => (
          <Typography
          fontFamily="segoe UI"
          fontWeight="700"
          maxWidth="530px"
          pt="2vh"
          lineHeight="normal"
            key={index}
            sx={{
              fontSize: `clamp(1.2rem, 3vw, 3rem)`,
              color: index === currentWordIndex ? "#EC2C6D" : "black",
            }}
            className={index === currentWordIndex ? "animated-word" : ""}
          >
            {`${word} `}
          </Typography>
        ))}
      </Stack>
    </>
  );
}

export default BouncingParagraph;
