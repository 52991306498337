import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../ui components/Navbar";
import Home from "./Home";
import Ourstory from "./Ourstory";
import Pricing from "./Pricing";
import Benefits from "./Benefits";
import Contact from "./Contact";
import { Box } from "@mui/material";
import Footer from "../ui components/Footer";
import { FormContext } from "./ContextProvider";



export default function Main(){
  useEffect(() => {
    document.title = 'AgentProxy';
  }, []);
  //// scroll links

    const homeRef = useRef(null);
  const ourstoryRef = useRef(null);
  const pricingRef = useRef(null);
  const benefitsRef = useRef(null);
  const contactRef = useRef(null);

  const handleScroll = (sectionId) => {
    const sectionRef = getSectionRef(sectionId);

    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getSectionRef = (sectionId) => {
    switch (sectionId) {
      case "home":
        return homeRef;
      case "ourstory":
        return ourstoryRef;
      case "pricing":
        return pricingRef;
      case "benefits":
        return benefitsRef;
      case "contact":
        return contactRef;
      default:
        return null;
    }
  };

    return(
        <Box
      className="App"
      height="100vh"
      sx={{
        backgroundColor:'#F6ECDF',
        backgroundImage: "url(./assets/images/background1.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        "&:hover": {
          backgroundImage: "url(./assets/images/background2.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        },
        borderRadius: "0% 0% 50% 50% / 15% 15%",
        borderBottom: "50px solid #EC2C6D",
      }}
    >
        <Navbar handleScroll={handleScroll} />
      <Home ref={homeRef} someProp="someValue" />
      <Ourstory ref={ourstoryRef} someProp="someValue" />
      <Pricing ref={pricingRef} someProp="someValue" />
      <Benefits ref={benefitsRef} someProp="someValue" />
      <Contact ref={contactRef} someProp="someValue" />
      <Footer/>
    </Box>
    )
}