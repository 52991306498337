import React, { useEffect } from "react";
import Navbar from "../ui components/Navbar";
import Footer from "../ui components/Footer";
import { Box, Typography } from "@mui/material";
export default function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar currentPage='privacy' />
      <Box padding={{ xs: "10vh 50px", md: "20vh 15vw" }}>
        <Typography
          className="border-bottom-centered font"
          textAlign="center"
          fontSize="clamp(1.2rem, 3vw, 3rem)"
          fontWeight="700"
          mb={{ xs: "5vh", md: "7vh" }}
        >
          Privacy Policy
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>Effective Date: 01 Dec 2023</b>
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          This privacy notice discloses the privacy practices for the AgentProxy
          App available on Google Play Store and App Store. This privacy notice
          applies solely to information collected by this App. It will notify
          you of the following:
          <ol>
            <li>
              What personally identifiable information is collected from you
              through the App, how it is used and with whom it may be shared.
            </li>
            <li>
              What choices are available to you regarding the use of your data.
            </li>
            <li>
              The security procedures in place to protect the misuse of your
              information.
            </li>
            <li>How you can correct any inaccuracies in the information.</li>
          </ol>
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>Information Collection, Use, and Sharing</b>
          <br />
          <br />
          In order to use this App, a user must first complete the registration
          form. During registration a user is required to give certain
          information (such as name, phone number email address, and the
          brokerage they work for/are employed by). Your activity on this App
          might also be collected within our own Firebase database account. This
          information is used to contact you about the products/services on our
          site in which you have expressed interest.
          <br />
          <br />
          We are the sole owners of the information collected on this site. We
          only have access to/collect information that you voluntarily give us
          on our App, via email or other direct contact from you. We will not
          sell or rent this information to anyone.
          <br />
          <br />
          We will use your information to respond to you, regarding the reason
          you contacted us. We will not share your information with any third
          party outside of our organization, other than as necessary to fulfill
          your request (e.g. real estate lawyer, real estate agents, or real
          estate broker of records)
          <br />
          <br />
          Unless you ask us not to, we may contact you via email or phone in the
          future to tell you about specials, new products or services, or
          changes to this privacy policy.
          <br />
          <br />
          This usage complies with all applicable laws including the
          requirements of the Personal Information Protection Act.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>Your Access to and Control Over Information</b> <br />
          <br />
          You may opt out of any future contacts from us at any time. You can do
          the following at any time by contacting us via the email address or
          phone number given on our App:
          <ol>
            <li>See what data we have about you, if any.</li>
            <li>Change/correct any data we have about you.</li>
            <li>Have us delete any data we have about you.</li>
            <li>Express any concern you have about our use of your data.</li>
          </ol>
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>Information Sharing with Real Estate Boards</b>
          <br />
          <br />
          You agree that the personal information that you provide may be
          collected, used or disclosed, including the fact that Personal
          Information may be shared with real estate boards for auditing and/or
          legal purposes.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>Security</b>
          <br />
          <br />
          We take precautions to protect your information. When you submit
          sensitive information via the App, your information is protected both
          online and offline.
          <br />
          <br />
          Wherever we collect sensitive information, that information is
          encrypted and transmitted to us in a secure way.
          <br />
          <br />
          While we use encryption to protect sensitive information transmitted
          online, we also protect your information offline. Only employees who
          need the information to perform a specific job (for example, billing,
          customer service, or property showings related procedures) are granted
          access to personally identifiable information. The computers/servers
          in which we store personally identifiable information are kept in a
          secure environment.
          <br />
          <br />
        </Typography>
        <Typography
          className="font"
          fontSize="clamp(1rem, 3vw, 1.4rem)"
          textAlign="justify"
          fontWeight="400"
          lineHeight="normal"
          pt="3vh"
        >
          <b>
            If you feel that we are not abiding by this privacy policy, you
            should contact us immediately via ahmad@agentproxy.ca
          </b>
        </Typography>
      </Box>
      <Footer />
    </>
  );
}
