import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../ui components/Footer";
import Navbar from "../ui components/Navbar";

export default function FormName() {
    const [userName, setUserName] = useState("")
    const navigate = useNavigate()
    const handleNameChange = (event) => {
        setUserName(event.target.value);
    };

    const handleRightButtonClick = () => {
        if (userName.trim() !== '') {
            // Only navigate if the TextField is not empty
            navigate('/congratulations');
        }
    };
    return (
        <>
            <Navbar currentPage = "congratulations" />
            <Box padding={{xs:'10vh 50px', md:'20vh 15vw'}} height={{xs:'50vh', md:'70vh'}} display='flex' flexDirection='column' justifyContent='center'>
                <div style={{ margin: '0 auto' }}>
                    <img src="./assets/images/congratulations.png" style={{ width:'clamp(200px, 50vw, 600px)' }} />
                </div>
                <Typography fontSize='clamp(1.2rem, 2vw, 2rem)' fontFamily='Mulish' textAlign='center' fontWeight='400' padding='5vh 5vw'>
                    You have successfully joined the AgentProxy waitlist!
                    Stay tuned for updates and for our App’s launch coming very 🔜
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Button
                        onClick={() => navigate("/")}
                        sx={{
                            borderRadius: '100px',
                            backgroundColor: '#EC2C6D',
                            color: 'white',
                            fontWeight: '700',
                            fontFamily: 'Segoe UI',
                            fontSize: 'clamp(1.2rem, 3vw, 2.5rem)',
                            textTransform: 'none',
                            padding: '5px 10px',
                            marginTop:{xs:'5vh', md:'10vh'},
                        }}
                    >
                        Go to Homepage
                    </Button>
                </div>
            </Box>
            <Footer />
        </>
    )
}